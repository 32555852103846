import React from "react";

// Components
import { CarouselV2 } from "components/CarouselV2/CarouselV2";
import { ProfileResourcesPreviewCard } from "./components/ProfileResourcesPreviewCard/ProfileResourcesPreviewCard";

// CSS
import styles from "./profile-resources.module.scss";

// Hooks
import { useProfileResources } from "./useProfileResources";

// Localization
import { useTranslation } from "localization/localization";

export const ProfileResources = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();
    const { isLoadingEmbeddedMessages, messages } = useProfileResources();

    /**
     * End Hooks
     */

    function renderLoading() {
        return [
            <ProfileResourcesPreviewCard isLoading key={crypto.randomUUID()} />,
        ];
    }

    function renderMessages() {
        if (messages) {
            return messages?.map((message) => (
                <ProfileResourcesPreviewCard
                    isLoading={isLoadingEmbeddedMessages}
                    key={message?.metadata?.messageId}
                    message={message}
                />
            ));
        }
        return [];
    }

    if (isLoadingEmbeddedMessages || (messages && messages.length > 0)) {
        return (
            <div className={styles.profileResources}>
                <CarouselV2
                    classNames={{
                        container: styles.carousel,
                        item: styles.item,
                        wrapper: "",
                    }}
                    gap={16}
                    maxItems={messages?.length || 1}
                    title={t("resources", { ns: "common" })}
                >
                    {isLoadingEmbeddedMessages
                        ? renderLoading()
                        : renderMessages()}
                </CarouselV2>
            </div>
        );
    }

    return null;
};
