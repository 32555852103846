export const IterableEmbeddedMessagesPlacementId = Object.freeze({
    DASHBOARD_ADS_PROD: 1262,
    DASHBOARD_ADS_STAGE: 1256,
    DASHBOARD_ALERTS_PROD: 817,
    DASHBOARD_ALERTS_STAGE: 813,
    DASHBOARD_MESSAGES_PROD: 358,
    DASHBOARD_MESSAGES_STAGE: 618,
    PROFILE_RESOURCES_STAGE: 1348,
    PROFILE_RESOURCES_PROD: 1347,
});
