import React from "react";
import cx from "classnames";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./toggle-switch.module.scss";

export const ToggleSwitch = ({
    checked,
    classes,
    cypressTestId,
    labelSwitchText,
    name,
    onChange,
}) => {
    function handleLabelKeyPress(e) {
        if (e.code === "Space") {
            onChange();
        }
    }

    return (
        <div className={cx(styles["toggle-switch"], classes)}>
            <label
                className={styles.text}
                data-cy={cypressTestId}
                htmlFor={name}
                onKeyDown={handleLabelKeyPress}
                tabIndex="0"
            >
                <Text>{labelSwitchText}</Text>
            </label>
            <div className={styles.switch}>
                <input
                    aria-checked={checked}
                    checked={checked}
                    className={styles.input}
                    id={name}
                    name={name}
                    onChange={onChange}
                    type="checkbox"
                />
                <label
                    className={cx(styles.label, {
                        [styles["label--checked"]]: checked,
                    })}
                    htmlFor={name}
                    onKeyDown={handleLabelKeyPress}
                    tabIndex="0"
                >
                    <span className={styles.button}></span>
                </label>
            </div>
        </div>
    );
};
